var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-screen flex w-full bg-img vx-row no-gutter sm:items-center justify-center sm:pt-16",attrs:{"id":"page-login"}},[_c('div',{staticClass:"vx-col w-full md:w-1/2 lg:w-3/4 xl:w-3/5 m-0 sm:m-4"},[_c('vx-card',[_c('div',{staticClass:"full-page-bg-color min-h-screen sm:min-h-full",attrs:{"slot":"no-body"},slot:"no-body"},[_c('div',{staticClass:"vx-row no-gutter justify-center items-center"},[_c('div',{staticClass:"vx-col hidden w-full lg:block lg:w-1/2"},[_c('picture',[_c('source',{attrs:{"type":"image/webp","srcset":require("@/assets/images/logo/logo.webp")}}),_c('source',{attrs:{"type":"image/png","srcset":require("@/assets/images/logo/logo.png")}}),_c('img',{staticClass:"mx-auto",staticStyle:{"width":"80%"},attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":"login"}})])]),_c('div',{staticClass:"vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg p-4 sm:p-0"},[_c('div',{staticClass:"p-8 login-tabs-container"},[_c('div',{staticClass:"vx-card__title mb-4"},[_c('h4',{staticClass:"mb-12"},[_vm._v(" Sign into MyLastWill Management ")])]),_c('form',{on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.authenticate.apply(null, arguments)}}},[_c('vs-input',{staticClass:"w-full",attrs:{"name":"email","icon-no-border":"","icon":"icon icon-user","icon-pack":"feather","label":"Email","danger":_vm.emailError,"danger-text":_vm.emailError,"size":_vm.$store.getters.windowBreakPoint ===
                                        'xs'
                                            ? 'large'
                                            : null},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('vs-input',{staticClass:"w-full mt-6 pb-5",attrs:{"type":"password","name":"password","icon-no-border":"","icon":"icon icon-lock","icon-pack":"feather","label":"Password","danger":_vm.passwordError,"danger-text":_vm.passwordError,"size":_vm.$store.getters.windowBreakPoint ===
                                        'xs'
                                            ? 'large'
                                            : null},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"flex flex-row-reverse mt-6"},[_c('vs-button',{staticClass:"float-right w-full sm:w-1/3",attrs:{"size":_vm.$store.getters
                                                .windowBreakPoint === 'xs'
                                                ? 'large'
                                                : null},on:{"click":_vm.authenticate}},[_vm._v("Login")])],1)],1)])])])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }