<template>
    <div
        class="h-screen flex w-full bg-img vx-row no-gutter sm:items-center justify-center sm:pt-16"
        id="page-login"
    >
        <div class="vx-col w-full md:w-1/2 lg:w-3/4 xl:w-3/5 m-0 sm:m-4">
            <vx-card>
                <div
                    slot="no-body"
                    class="full-page-bg-color min-h-screen sm:min-h-full"
                >
                    <div class="vx-row no-gutter justify-center items-center">
                        <div class="vx-col hidden w-full lg:block lg:w-1/2">
                            <picture>
                                <source
                                    type="image/webp"
                                    srcset="@/assets/images/logo/logo.webp"
                                />
                                <source
                                    type="image/png"
                                    srcset="@/assets/images/logo/logo.png"
                                />
                                <img
                                    src="@/assets/images/logo/logo.png"
                                    alt="login"
                                    class="mx-auto"
                                    style="width: 80%"
                                />
                            </picture>
                        </div>

                        <div
                            class="vx-col w-full sm:w-full md:w-full lg:w-1/2 d-theme-dark-bg p-4 sm:p-0"
                        >
                            <div class="p-8 login-tabs-container">
                                <div class="vx-card__title mb-4">
                                    <h4 class="mb-12">
                                        Sign into MyLastWill Management
                                    </h4>
                                </div>

                                <form @keyup.enter="authenticate">
                                    <vs-input
                                        name="email"
                                        icon-no-border
                                        icon="icon icon-user"
                                        icon-pack="feather"
                                        label="Email"
                                        v-model="email"
                                        class="w-full"
                                        :danger="emailError"
                                        :danger-text="emailError"
                                        :size="
                                            $store.getters.windowBreakPoint ===
                                            'xs'
                                                ? 'large'
                                                : null
                                        "
                                    />

                                    <vs-input
                                        type="password"
                                        name="password"
                                        icon-no-border
                                        icon="icon icon-lock"
                                        icon-pack="feather"
                                        label="Password"
                                        v-model="password"
                                        class="w-full mt-6 pb-5"
                                        :danger="passwordError"
                                        :danger-text="passwordError"
                                        :size="
                                            $store.getters.windowBreakPoint ===
                                            'xs'
                                                ? 'large'
                                                : null
                                        "
                                    />
                                    <!--
                  <div class="flex flex-wrap justify-between my-5">
                    <router-link to="">Forgot Password?</router-link>
                  </div>
                  -->
                                    <!--<vs-button type="border">Register</vs-button>-->
                                    <div class="flex flex-row-reverse mt-6">
                                        <vs-button
                                            @click="authenticate"
                                            class="float-right w-full sm:w-1/3"
                                            :size="
                                                $store.getters
                                                    .windowBreakPoint === 'xs'
                                                    ? 'large'
                                                    : null
                                            "
                                            >Login</vs-button
                                        >
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </vx-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
        }
    },
    mounted() {},
    watch: {
        loginError: {
            immediate: true,
            deep: true,
            handler(value) {
                console.log('value wather', value)
                if (value && value.detail)
                    this.$vs.notify({
                        title: 'Login Error',
                        text: value.detail,
                        color: 'warning',
                        position: 'top-center',
                        time: 6000,
                    })
            },
        },
    },
    computed: {
        loginError() {
            return this.$store.getters.loginError
        },
        emailError() {
            if (this.loginError && this.loginError.email)
                return this.loginError.email[0]
            return null
        },
        passwordError() {
            if (this.loginError && this.loginError.password)
                return this.loginError.password[0]
            return null
        },
    },
    methods: {
        authenticate() {
            this.$store.commit('loginError', null)
            this.$store.dispatch('obtainToken', {
                email: this.email,
                password: this.password,
            })
        },
    },
}
</script>
